import { Card, Descriptions, Flex, Spin, Tag, Typography } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { routes } from "statics";
import { Proposal, Wallet } from "types";

type TotalInfoProps = {
  proposalLoading: boolean;
  refetchProposal: () => void;
  proposal: Proposal | undefined;
};

const TotalInfo: React.FC<TotalInfoProps> = ({
  proposalLoading,
  proposal,
  refetchProposal,
}) => {
  const status: Record<Proposal["status"], React.ReactElement> = {
    INIT: <Tag color="default">Init</Tag>,
    APPROVED: <Tag color="blue">Approved</Tag>,
    SETTLING: <Tag color="blue">Settling</Tag>,
    FAILED: <Tag color="red">Failed</Tag>,
    SETTLED: <Tag color="green">Settled</Tag>,
    CANCELED: <Tag color="red">Canceled</Tag>,
    PENDING: <Tag color="warning">Pending</Tag>,
    REJECTED: <Tag color="red">Canceled</Tag>,
    COMPLETED: <Tag color="blue">Approved</Tag>,
    "": <Tag color="default">-</Tag>,
  };

  const navigate = useNavigate();
  const { Text } = Typography;

  const typeData: Record<string, { user: Wallet; ld: Wallet; title: string }> =
    {
      DEPOSIT_LD: {
        user: proposal?.destination_wallet as Wallet,
        ld: proposal?.source_wallet as Wallet,
        title: "Deposit",
      },
      WITHDRAW_LD: {
        user: proposal?.source_wallet as Wallet,
        ld: proposal?.destination_wallet as Wallet,
        title: "Withdraw",
      },
    };

  const paymentMethod = {
    CASH: "Cash",
    BANK_TRANSFER: "Bank transfer",
    "": "",
  };
  const rateType = {
    MANUAL: "Manual",
    SYSTEM: "System",
    "": "",
  };

  const descriptionsItems = proposal?.transaction_method?.category
    ? [
        {
          key: "type",
          label: "Type",
          children: (
            <Tag
              color={
                proposal?.transaction_method?.category === "DEPOSIT_LD"
                  ? "green"
                  : "red"
              }
            >
              {typeData[proposal?.transaction_method?.category]?.title || "-"}
            </Tag>
          ),
        },
        {
          key: "user",
          label: "User",
          children: typeData[proposal?.transaction_method?.category]?.user?.user ? (
            <Text
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() =>
                navigate(
                  routes.client.href(
                    typeData[proposal?.transaction_method?.category].user?.user
                      ?.id
                  )
                )
              }
            >
              {typeData[proposal?.transaction_method?.category].user?.user
                ?.profile?.first_name +
                " " +
                typeData[proposal?.transaction_method?.category].user?.user
                  ?.profile?.first_name}
            </Text>
          ) : (
            "-"
          ),
        },
        {
          key: "ld",
          label: "LD",
          children: typeData[proposal?.transaction_method.category]?.ld?.user ? (
            <Text
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() =>
                navigate(
                  routes.client.href(
                    typeData[proposal?.transaction_method.category]?.ld?.user
                      ?.id
                  )
                )
              }
            >
              {typeData[proposal?.transaction_method.category]?.ld?.user
                ?.profile.first_name +
                " " +
                typeData[proposal?.transaction_method.category]?.ld?.user
                  ?.profile.first_name}
            </Text>
          ) : (
            "-"
          ),
        },
        {
          key: "amount",
          label: "Amount",
          children: typeData[proposal?.transaction_method.category]?.user?.currency ? (
            <Text>
              {proposal?.amount}
              <Text
                style={{
                  fontSize: "10px",
                  paddingLeft: 2,
                  color: "green",
                }}
              >
                {
                  typeData[proposal?.transaction_method.category]?.user
                    ?.currency?.symbol
                }
              </Text>
            </Text>
          ) : (
            "-"
          ),
        },
        {
          key: "rate_type",
          label: "Rate type",
          children: <Text>{rateType[proposal?.type || ""]}</Text>,
        },
        {
          key: "rate",
          label: "Rate",
          children: <Text>{proposal?.rate}</Text>,
        },
        {
          key: "status",
          label: "Status",
          children: <Text>{status[proposal?.status]}</Text>,
        },
        {
          key: "payment_method",
          label: "Payment method",
          children: (
            <Text>{paymentMethod[proposal?.payment_method || ""]}</Text>
          ),
        },
        {
          key: "pay_with",
          label: "Pay with",
          children: <Text>{proposal?.pay_with}</Text>,
        },
        {
          key: "note",
          label: "Note",
          children: <Text>{proposal?.note}</Text>,
        },
        {
          key: "create",
          label: "Created at",
          children: (
            <Text>
              {dayjs(proposal?.created_at).format("YYYY-MM-DD HH:mm:ss")}
            </Text>
          ),
        },
      ]
    : [];

  return (
    <Card>
      {proposalLoading ? (
        <Flex justify="center">
          <Spin />
        </Flex>
      ) : (
        <Descriptions bordered layout="vertical" items={descriptionsItems} />
      )}
    </Card>
  );
};

export default TotalInfo;
